import React, { useState, useEffect } from "react";
import { useMessage } from '../../Hooks';
import BaseLayout from "../../Components/BaseLayout";
import MessageList from "../../Components/Chat/MessageList";
import { useParams } from "react-router";
import useChat from "../../Hooks/useChat";

function Chat() {
  const { key } = useParams();
  const { messages } = useMessage(key);
  const { chats } = useChat(localStorage.getItem('@cicop-chat/loginHash'));
  const [title, setTitle] = useState("");

  useEffect(() => {
    const chatTitle = chats.filter((chat) => chat.key === key)[0]?.title;
    setTitle(chatTitle);
    document.title = `Central de Chamados CICOP - Atendimento ${chatTitle}`;
  }, [key, chats]);

  return (
    <BaseLayout title={title}>
      <MessageList messages={messages}/>
    </BaseLayout>
  );
}

export default Chat;

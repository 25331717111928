import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { ChatsService } from "../../Services";
import { useOnsites } from "../../Hooks";
import { useHistory, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ModalCreateChat({ open, handleClose }) {
  const history = useHistory();
  const { userUuid } = useParams();
  const classes = useStyles();
  const [name, setName] = useState("");
  const [local, setLocal] = useState("");
  const { onsites } = useOnsites();

  const handleSubmitButton = useCallback(async (title) => {
    const chat = await ChatsService.creatChat({
      title,
      userUuid,
      userHash: "",
    });

    if (chat) {
      handleClose();
      history.push(`/suporte/${userUuid}`);
      window.location.reload();
    }

  }, []);

  return (
    <div>
      <Dialog disableEscapeKeyDown open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Canal de Atendimento CICOP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para iniciar uma conversa primeiro selecione seu local e digite seu nome
          </DialogContentText>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <InputLabel id="local">Local</InputLabel>
            <Select
              labelId="local"
              fullWidth
              value={local}
              onChange={(event) => setLocal(event.target.value)}
              label="Local"
            >
              <MenuItem value="">
                <em>Selecione seu local</em>
              </MenuItem>
              {onsites.map((onsite) => (
                <MenuItem key={onsite.name} value={onsite.name}>
                  {onsite.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            onChange={(event) => setName(event.target.value)}
            autoFocus
            id="name"
            label="Nome"
            type="text"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => window.close()} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleSubmitButton(`${local} - ${name}`)} color="primary">
            Iniciar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { TextField, IconButton } from "@material-ui/core";
import { Send as SendIcon } from "@material-ui/icons";
import { MessagesService } from '../../Services';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  inputDrawer: {
    position: "fixed",
    zIndex: theme.zIndex.drawer - 2,
    marginTop: theme.spacing(1),
    bottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(255, 255, 255, 1)",
    width: `calc(100vw - ${theme.spacing(6)}px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100vw - 240px - ${theme.spacing(6)}px)`,
    },
  },
  input: {
    position: "fixed",
    zIndex: theme.zIndex.drawer - 2,
    marginTop: theme.spacing(1),
    bottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(255, 255, 255, 1)",
    width: `calc(100vw - ${theme.spacing(6)}px)`,
  },
  sendButton: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: theme.zIndex.drawer - 1,
  },
}));

function MessageInput({ hasDrawer, chat }) {
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const params = useParams();

  const handleMessageSend = (event = null) => {
    if ((!event || event.key === "Enter") && message.length) {
      MessagesService.sendMessage(message, chat?.id ? chat.id : params.key, params?.key ? localStorage.getItem('@cicop-chat/loginHash') : params.userUuid);
      setMessage("");
    }
  };

  return (
    <>
      <TextField
        placeholder="Digite sua mensagem..."
        className={hasDrawer ? classes.inputDrawer : classes.input}
        onChange={(event) => setMessage(event.target.value)}
        onFocus={(event) =>
          MessagesService.markAllAsRead(
            chat?.id ? chat.id : params.key,
            params?.key ? localStorage.getItem("@cicop-chat/loginHash") : params.userUuid
          )
        }
        onKeyPress={handleMessageSend}
        value={message}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />
      <IconButton color="inherit" onClick={() => handleMessageSend()} className={classes.sendButton}>
        <SendIcon className={classes.sendButton} />
      </IconButton>
    </>
  );
}

export default MessageInput;
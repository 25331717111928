import firebase from "firebase";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBRn0jdSh5792ztNqrp1BAvLcjw298U7B8",
  authDomain: "chat-cicop-f5dd9.firebaseapp.com",
  projectId: "chat-cicop-f5dd9",
  storageBucket: "chat-cicop-f5dd9.appspot.com",
  messagingSenderId: "590359571214",
  appId: "1:590359571214:web:736deb5e256b76bf6a055f",
};

firebase.initializeApp(firebaseConfig);

export { firebase };

export const db = firebase.firestore();

import { useEffect, useState } from "react";
import { db } from "../Firebase";
import { ChatsService, MessagesService } from '../Services';

function useChat(user, isCommomUser = false) {
  const [chats, setChats] = useState([]);

  useEffect(() => {
    if (user) {
      ChatsService.observeChats(user, isCommomUser, (querySnapshot) => {
        setChats([]);
        querySnapshot.docs.forEach((doc) => {
          MessagesService.getMessages(doc.id).then((chatMessages) => {
            setChats(oldValue => [
              ...oldValue,
              {
                key: doc.id,
                messages: chatMessages,
                ...doc.data(),
              }
            ]);
          });
        });
      });
    }
  }, [user]);

  return {
    chats: [...new Map(chats.map((item) => [item['title'], item])).values()],
  };
}

export default useChat;

import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BaseLayout from "../../Components/BaseLayout";
import {useChat} from "../../Hooks";
import CardChat from "./CardChat";

function List() {
  const loginHash = localStorage.getItem('@cicop-chat/loginHash');
  const { chats } = useChat(loginHash);
  const [title] = useState("Painel de Atendimento");

  useEffect(() => { document.title = `Central de Chamados CICOP - ${title}` }, [title]);
  
  return (
    <BaseLayout showDrawer={false} title={title}>
      <Typography paragraph>
        Olá <strong>CICOP</strong>, selecione um dos chamados abaixo para responder
      </Typography>
      <Grid container spacing={2}>
        {chats.map((chat) => (
          <CardChat key={chat.key} chat={chat} />
        ))}
      </Grid>
    </BaseLayout>
  );
}

export default List;

import { useEffect, useState } from "react";
import { ChatsService, MessagesService } from "../Services";

function useCommomUserMessage(userUuid) {
  const [messages, setMessages] = useState([]);
  const [chatState, setChatState] = useState(null);

  useEffect(async () => {
    const chat = await ChatsService.getChat(userUuid);
    if (chat) {
      setChatState(chat);
      MessagesService.observeMessages(chat.id, (querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setMessages(messages);
      });
    } else {
      setChatState(undefined);
    }
  }, [userUuid]);

  return {
    messages,
    chat: chatState,
  };
}

export default useCommomUserMessage;

import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BaseLayout from "../Components/BaseLayout";
import { useParams, Redirect } from "react-router";

function Login() {
  const { loginHash } = useParams();
  const localStorageLoginHash = localStorage.getItem('@cicop-chat/loginHash');
  const [title] = useState("Login");


  useEffect(() => {
    (loginHash && loginHash !== localStorageLoginHash)
      ? localStorage.setItem("@cicop-chat/loginHash", loginHash)
      : null;
  }, []);

  useEffect(() => {
    document.title = `Central de Chamados CICOP - ${title}`;
  }, [title]);

  return (
    <BaseLayout showDrawer={false} title={title}>
      {!loginHash && !localStorageLoginHash ? (
        <Typography paragraph>Hash inválido para realizar login na plataforma, contate nosso suporte.</Typography>
      ) : (
        <Redirect to="/" exact />
      )}
    </BaseLayout>
  );
}

export default Login;

import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import { ChamadosList, ChamadosChat } from "../Screens/Chamados";
import { SuporteChat } from "../Screens/Suporte";
import Login from "../Screens/Login";

export default function Routes() {
  const loginHash = localStorage.getItem('@cicop-chat/loginHash');
  return (
    <Router>
      <Switch>
        <Route path={["/login/:loginHash", "/login"]}>
          <Login />
        </Route>
        <Route path="/" exact>
          {loginHash ? <ChamadosList /> : <Redirect to="/login" />}
        </Route>
        <Route path="/chat/:key">{loginHash ? <ChamadosChat /> : <Redirect to="/login" />}</Route>
        <Route path={["/suporte/:userUuid"]}>
          <SuporteChat />
        </Route>
      </Switch>
    </Router>
  );
}

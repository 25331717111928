import { useState } from "react";

function useOnsites() {
  const allOnsites = [
    { id: "115", name: "Ambev Cuiabá" },
    { id: "101", name: "Andina Ribeirão Preto" },
    { id: "126", name: "Ambev Anápolis" },
    { id: "130", name: "Ambev Pirai" },
    { id: "132", name: "Ambev Agudos" },
    { id: "143", name: "Ambev Teresina" },
    { id: "153", name: "Ambev Jundiaí" },
    { id: "163", name: "Ambev Ponta Grossa" },
    { id: "187", name: "Ambev Nova Rio" },
    { id: "201", name: "Ambev Uberlândia" },
    { id: "208", name: "Ambev Macacu" },
    { id: "202", name: "Ambev Jaguariúna" },
    { id: "48", name: "Cerpa Belém" },
    { id: "127", name: "Coca Marília" },
    { id: "128", name: "Coca Marília" },
    { id: "133", name: "Coca Belem" },
    { id: "46", name: "Saab Linhares" },
    { id: "40", name: "Heineken Araraquara" },
    { id: "119", name: "Heineken Horizonte" },
    { id: "121", name: "Femsa Itabirito" },
    { id: "136", name: "Schin Cachoeiras" },
    { id: "138", name: "Heineken Pacatuba" },
    { id: "142", name: "Heineken Caxias" },
    { id: "157", name: "GP Boituva" },
    { id: "158", name: "GP Boituva" },
    { id: "164", name: "Femsa Curitiba" },
    { id: "165", name: "Heineken Ponta Grossa" },
    { id: "168", name: "GP Rondonópolis" },
    { id: "175", name: "Solar Maceió" },
    { id: "177", name: "Heineken Igrejinha" },
    { id: "179", name: "Heineken Araraquara" },
    { id: "190", name: "Grupo Simões Belem" },
    { id: "203", name: "GP Uberaba" },
  ];

  const onsitesOrdered = allOnsites.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  const [onsites] = useState(onsitesOrdered);

  return {
    onsites,
  };
}

export default useOnsites;

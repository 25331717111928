import { Grid, Typography, Card, CardActions, Button, CardContent, Badge, Divider } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { ChatsService } from "../../Services";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    minHeight: 180,
  },
  unreadMessage: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 1)",
  },
  readMessage: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.3)",
  },
  cardContent: {
    padding: 12,
    paddingTop: 0,
    boxShadow: theme.shadows[0],
  },
  divider: {
    marginTop: 8,
    marginBottom: 16,
    marginLeft: -28,
    marginRight: -28,
  },
  answerButton: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}));

function CardChat({ chat }) {
  const loginHash = localStorage.getItem("@cicop-chat/loginHash");
  const classes = useStyles();
  const unreadMessages = chat?.messages?.filter(
    (message) => message.user !== loginHash && message.read_at === null
  );

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Badge
        color="primary"
        className={classes.root}
        badgeContent={unreadMessages?.length}
        invisible={unreadMessages?.length === 0}
      >
        <Card className={[classes.root, classes.cardContent]}>
          <CardContent>
            <Typography variant="h6">{chat.title}</Typography>
            <Typography
              className={
                chat.messages[0]?.read_at === null && chat.messages[0]?.user !== loginHash
                  ? classes.unreadMessage
                  : classes.readMessage
              }
              variant="caption"
              component="p"
            >
              {new Date(chat.messages[0]?.created_at?.seconds * 1000).toLocaleString("pt-BR")}
            </Typography>
            <Divider className={classes.divider} />
            <Typography
              className={
                chat.messages[0]?.read_at === null && chat.messages[0]?.user !== loginHash
                  ? classes.unreadMessage
                  : classes.readMessage
              }
              variant="body2"
              component="p"
            >
              {chat.messages[0]?.message}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => ChatsService.answerChat(chat.key)}
              component={Link}
              to={`/chat/${chat.key}`}
              size="small"
              className={classes.answerButton}
            >
              Responder
            </Button>
          </CardActions>
        </Card>
      </Badge>
    </Grid>
  );
}

export default CardChat;

import React, { useState, useEffect } from "react";
import { useCommomUserMessage, useChat } from "../../Hooks";
import BaseLayout from "../../Components/BaseLayout";
import MessageList from "../../Components/Chat/MessageList";
import { useHistory, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import ModalCreateChat from "./ModalCreateChat";

function Chat() {
  const { userUuid } = useParams();
  const { messages, chat } = useCommomUserMessage(userUuid);
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTitle("Canal de Atendimento");
    document.title = `CICOP - Canal de Atendimento`;

    if (userUuid && chat === undefined) {
      handleOpen();
    }
  }, [userUuid, chat]);

  useEffect(() => {
    const localStorageUserUuid = localStorage.getItem('@cicop-chat/userUuid');

    if (!localStorageUserUuid) {
      localStorage.setItem("@cicop-chat/userUuid", userUuid);
    } else {
      if (chat === undefined) {
        handleOpen();
      } else if (userUuid && userUuid !== localStorageUserUuid) {
        history.replace(`/suporte/${userUuid}`);
      }
    }
  }, [userUuid]);


  return (
    <BaseLayout title={title} showDrawer={false}>
      <MessageList messages={messages} hasDrawer={false} chat={chat} />
      <ModalCreateChat open={open} handleClose={handleClose} />
    </BaseLayout>
  );
}

export default Chat;

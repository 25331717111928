import { db, firebase } from "../Firebase";

const ChatsService = {
  async getChat(userUuid) {
    if (!userUuid) {
      return;
    }
    const chatsDocs = await db.collection("chats").where('userUuid', '==', userUuid).get();
    const chats = [];
    chatsDocs.forEach((chat) => {
      chats.push({
        id: chat.id,
        ...chat.data()
      });
    });
    return chats[0];
  },

  observeChats(user, isCommomUser, callback) {
    if (isCommomUser) {
      db.collection("chats").where("userUuid", "==", user).orderBy('last_message_at', 'desc').onSnapshot(callback);
    } else {
      db.collection("chats")
        .where("userHash", "in", [user, ""])
        .orderBy("last_message_at", "desc")
        .onSnapshot(callback);
    }
  },

  async creatChat(data) {
    const chat = await db.collection("chats").add({
      ...data,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      last_message_at: null,
    });

    return chat.id;
  },

  async answerChat(chat) {
    db.collection("chats")
      .doc(chat)
      .update({
        userHash: localStorage.getItem("@cicop-chat/loginHash"),
      });
  },
};

export default ChatsService;
